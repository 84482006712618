import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styles from "./App.module.css";
import { withAuthenticator, AmplifySignOut, AmplifySignUp } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import DashboardsView from "./components/dashboards/DashboardsView";
import DashboardView from "./components/dashboard/DashboardView";
import hamburger from "./img/hamburger.svg";

const App = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [idToken, setIdToken] = useState("");
  const [registered, setRegistered] = useState(false);
  const [userAttributes, setUserAttributes] = useState();

  useEffect(() => {
    const registerUserWithQuickSight = async () => {
      try {
        const response = await fetch(
          "https://api.dashboardnbt.nbtccloud.com/user/register",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, idToken, email, ...userAttributes }),
          }
        );
        const responseJson = await response.json();
        console.log("respJson", responseJson);
      } catch (error) {
        console.log(error);
      }
    };
    if (!registered && username && email && idToken && userAttributes) {
      registerUserWithQuickSight();
      setRegistered(true);
    }
  }, [username, email, idToken, registered, userAttributes]);

  useEffect(() => {
    const getCredsFunc = async () => {
      const promises = [Auth.currentSession(), Auth.currentUserInfo()];
      const [idToken, userInfo] = await Promise.all(promises);
      console.log(idToken, userInfo);
      const token = idToken.getIdToken().getJwtToken();
      const un = userInfo.username;
      const userEmail = userInfo.attributes && userInfo.attributes.email;
      const { family_name, given_name, phone_number, email_verified } = userInfo.attributes;
      console.log(token, "username", un, "email", userEmail);
      setUserAttributes({ lastName: family_name, firstName: given_name, phoneNumber: phone_number, emailVerified: email_verified });
      setIdToken(token);
      setUsername(un);
      setEmail(userEmail);
    };
    getCredsFunc();
  }, []);

  return (
    <Router>
      <Navbar bg="light" expand="lg">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <NavDropdown
              title={
                <img
                  src={hamburger}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item>
                <Link className={styles.mainlink} to="/">
                  Dashboards
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <AmplifySignOut />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className={styles.main}>
        <Switch>
          <Route exact path="/" render={() => <DashboardsView email={email}/>} />
          <Route
            path="/:dashboardId"
            children={<DashboardView email={email} idToken={idToken} />}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default withAuthenticator(App, {
  usernameAlias: "email",
  children: [(<AmplifySignUp key="1"
  headerText="Please sign up with NBTeam quicksight access"
  slot="sign-up"
        usernameAlias="email"
  formFields= {[
    {
      type: "given_name",
      key: "given_name",
      label: "First Name",
      placeholder: "First Name",
      required: true,
    },
    {
      type: "family_name",
      key: "family_name",
      label: "Last Name",
      placeholder: "Last Name",
      required: true,
    },
    {
      type: "email",
      label: "Email",
      placeholder: "email",
      required: true,
    },
    {
      type: "password",
      label: "Password",
      placeholder: "password",
      required: true,
    },
    {
      type: "phone_number",
      label: "Phone Number",
      placeholder: "phone number",
      required: false,
    },
  ] } />)]
});

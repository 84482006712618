import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { ListGroup, Spinner } from "react-bootstrap";
import styles from './DashboardsView.module.css';

const DashboardsView = (props) => {
  const [dashboards, setDashboards] = useState([]);
  const [id, setId] = useState()
  const { email } = props;
  
  const cb = useCallback(item => () => setId(item.dashboardId), [])
  useEffect(() => {
    const getListItems = async () => {
      try {
        const response = await fetch(
          "https://api.dashboardnbt.nbtccloud.com/dashboards/list",
          {
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email }),
          }
        );
        const responseJson = await response.json();
        console.log("respJson", responseJson);
        setDashboards([...responseJson]);
      } catch (error) {
        console.log(error);
      }
    };
    if (!dashboards.length && email) {
      getListItems();
    }
  }, [dashboards, email]);
  
  if (id) {
    return <Redirect push to={`/${id}`} />
  }
  
  if (!dashboards.length) {
    return <Spinner className={styles.spinner} animation="border" variant="primary" />
  }

  const listgroupitems = dashboards.map((item) => (
    <ListGroup.Item action key={item.dashboardId} onClick={cb(item)}>
      {item.name}
    </ListGroup.Item>
  ));
  
  return (
    <div>
      <ListGroup as="ul">{listgroupitems && listgroupitems}</ListGroup>
    </div>
  );
};

export default DashboardsView;

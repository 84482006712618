/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:18728423-48ba-4035-bcee-81463cf44fb4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IvV88i08i",
    "aws_user_pools_web_client_id": "3ut2a8dersmalekp5ja7edolo0",
    "oauth": {}
};


export default awsmobile;

import React, { useEffect, useState } from "react";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { useParams, withRouter } from "react-router-dom";
import styles from "./DashboardView.module.css";
import { Spinner } from "react-bootstrap";

const DashboardView = (props) => {
  const { email, idToken } = props;
  const { dashboardId } = useParams();
  const [dashboardUrlLoaded, setDashboardUrlLoaded] = useState(false);
  
  useEffect(() => {
    const showDashboardFunc = async () => {
      const response = await fetch(
        "https://api.dashboardnbt.nbtccloud.com/dashboards/getEmbeddedUrl",
        {
          mode: 'cors',
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, idToken, dashboardId }),
        }
      );
      const respJson = await response.json();
      const { url } = respJson;
      console.log("url", url);

      var options = {
        url: url,
        container: document.getElementById("container"),
        scrolling: "no",
        height: "100%",
        width: "100%",
        locale: "en-US",
        footerPaddingEnabled: true,
      };
      embedDashboard(options);
    };

    if (!dashboardUrlLoaded && email && idToken) {
      showDashboardFunc();
      setDashboardUrlLoaded(true);
    }
  }, [email, idToken, dashboardUrlLoaded, dashboardId]);

  if (!dashboardUrlLoaded) {
    return <Spinner className={styles.spinner} animation="border" variant="primary" />
  }

  return <div className={styles.container} id="container" />
};

export default withRouter(DashboardView);
